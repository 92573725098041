.sample {
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
}

.row-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-right: auto;
}

.backward-button {
  margin-left: auto;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #666666;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
}

.backward-button:hover {
  background-color: #555555;
}

.notification_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
}

.notification_list {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
}

.notification_element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  box-shadow: none;
}

.device-name {
  font-size: 1.25rem;
  background-color: #d9dcff;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  width: 200px;
}

.username-box {
  text-align: center;
  background-color: #cbe0ff;
  padding: 10px;
  border-radius: 8px;
  font-size: 1.25rem;
  margin-right: 20px;
}

.showBtn {
  padding: 10px 20px;
  background-color: #666666;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.showBtn:hover {
  background-color: #555555;
}
