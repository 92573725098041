.read-excel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
        width: 6.5rem;
        font-weight: 600;
    }
    .dataLabel {
        width: 20rem;
        border-radius: 0.625rem;
        outline: none;
        border: 1px solid #adadad;
    }
    .excelData-list {
        width: 100%;
        border-collapse: collapse;
        th {
            background-color: #f3b634;
        }
        th, td {
            border: 1px solid #000;
            padding: 0.3125rem;
            text-align: center;
        }
    }
    textarea {
        width: 50%;
        border-radius: 0.625rem;
        outline: none;
        border: 1px solid #adadad;
    }
    .save-file-btn {
        border: none;
        background-color: #f3b634;
        font-weight: 600;
        border-radius: 1.25rem;
    }
}

