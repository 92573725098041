.myData-container {
  display: flex;

  .myData-left {
    .yellow-btn {
      font-weight: 800;
      font-size: 1.5rem;
      background: #f3b634;
      padding: 0.5m;
      border-radius: 0.3125rem;
      margin-bottom: 0.5rem;
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      cursor: pointer;
      //border: none;
    }

    /*folder*/
    .myData-folder {
      //overflow-y: scroll;
      display: flex;
      flex-direction: column;
      border-radius: 0.625rem;
      border: 1px solid #d1d1d1;
      // box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.125);
      //width: 20%;
      //height: 20rem;
    }

    /*데이터 요약 정보*/
    .myData-summary {
      margin-top: 1rem;
      //overflow-y: scroll;
      border-radius: 0.625rem;
      border: 1px solid #d1d1d1;
      // box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.125);
      width: 100%;
      // height: 20rem;
      padding: 1rem 0.5rem;

      /*table*/
      .summary-table {
        //font-size: 0.8em;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #ffffff;

        th,
        td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: center;
        }

        th {
          background-color: #fdeecf;
        }

        tr:nth-child(even) {
          background-color: #f2f2f2;
        }

        tr:hover {
          background-color: #ccc;
          cursor: pointer;
        }
      }
    }
  }

  .myData-right {
    display: flex;
    justify-content: center;
    width: 100%;

    .excel-download {
      border: none;
      border-radius: 0.3125rem;
      width: 10rem;
      background: #f3b634;
      font-weight: 600;
    }

    .myData-detail {
      //font-size: 0.8em;
      margin-top: 0.5rem;
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #ddd;

      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
      }

      th {
        background-color: #fdeecf;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      tr:hover {
        background-color: #ccc;
        cursor: pointer;
      }

      input[type='checkbox'] {
        accent-color: #f3b634;
      }
    }

    .myData-detail-empty {
      //font-size: 0.8em;
      margin-top: 0.5rem;
      width: 100%;
      height: 20rem;

      border-collapse: collapse;
      border: 1px solid #ddd;

      th,
      td {
        border: 1px solid #ddd;
        padding: 20px;
        text-align: center;
      }

      th {
        background-color: #fdeecf;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      tr:hover {
        background-color: #ccc;
        cursor: pointer;
      }

      input[type='checkbox'] {
        accent-color: #f3b634;
      }
    }
  }
}
