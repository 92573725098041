.login {
  .loginInput {
    width: 20rem;
    background-color: #f2f2f2;
    outline: none;
    font-size: 1.25em;
    border: none;
    border-radius: 0.3125rem;
    padding: 0.2rem 1rem;
    margin-top: 0.5rem;
  }
  .loginInput::placeholder {
    color: #a0a0a0;
  }
  .login-btn {
    width: 20rem;
    background-color: #2f5f3a;
    color: #fff;
    margin-top: 1rem;
  }
  .login-btn:hover {
    background-color: #2f5f3a;
    color: #fff;
    opacity: 0.8;
  }
  .find {
    text-align: center;
    margin-top: 0.5rem;
    a,
    span {
      text-decoration: none;
      color: #a0a0a0;
      font-size: 0.75em;
    }
    a:hover {
      color: #2f5f3a;
    }
  }
}
