.what-we-do {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .split-layout {
        opacity: 0; 
        animation: fadeIn 1s ease-out forwards; 
        animation-delay: 0.5s; 

        margin-top: 3rem;
        display: flex; 
        align-items: center; 
        justify-content: center; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 0.625rem;
        //width: 80%;
        width: 70rem;
        height: 30rem;

        .text-side, .image-side {
            width: 50%; 
            display: flex;
            //align-items: center;
            justify-content: center;
            text-align: left;
        }
    
        .text-side {
            flex-direction: column; 
            padding: 40px;
            line-height: 1.75rem;
        }
    
        .image-side {
            img {
                max-width: 100%;
                height: 30rem;
                border-radius: 0 0.625rem 0.625rem 0;
            }
        }
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px); 
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}