.e-class-mydata {
  .myData-summary {
    margin-top: 1rem;
    height: 70vh;

    /*table*/
    .summary-table {
      border-collapse: collapse;
      border: 1px solid #ddd;
      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
      }
      th {
        background-color: #fdeecf;
      }
      tr:hover {
        background-color: #ececec;
        cursor: pointer;
      }
    }
  }
}
