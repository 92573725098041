/*form{
    width:50em;
}*/

#all {
  padding-left: 4em;
}

.form-control {
  width: 50%;
}

#S_botton {
  width: 6em;
  height: 2.3em;
  margin-left: 1.5em;
  margin-top: 1em;
}

#specificSizeInputName {
  width: 15em;
}

.text-center-1 {
  height: 8em;
  margin-left: 40%;
  width: 30%;
}

.Text {
  text-align: center;
  margin-top: 7em;
}

.pagination {
  justify-content: center;
  margin: 2em 0;
}

.page-link {
  color: black;
}
