.openAPI-search {
    select {
        width: 8rem;
        height: 2rem;
        border-radius: 0.625rem;
        margin-left: 1rem;
        outline: none;
    }
    .station-container {
        border-radius: 0.625rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 1rem;
        width: 45%;
        height: 8rem;
        //background: #f4c095;
        border: 1px solid #000;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
        margin-bottom: 1rem;
        span {
            margin-right: 0.3rem;
            border-radius: 1.25rem;
            background-color: #000;
            padding: 0.3rem 0.5rem;
            color: #fff;
            font-weight: 600;
        }
        a {
            color: #f06313;
        }
    }
}