.implementation-pic {
    figure {
        margin-right: 1rem;
        img {
            width: 15rem;
            height: 15rem;
        }
    }
    figcaption {
        text-align: center;
        margin-top: 0.3rem;
    }
}